import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { countries } from "../../constants";
import CloseIcon from "../../icons/CloseIcon";
import SearchSelect from "../form/searchSelect/SearchSelect";
import {
  toggleAddDestinationDialog,
  toggleUpdateDestinationDialog,
} from "../../helpers/ToggleDialog";
import ImageUpload from "../form/imageUpload/ImageUpload";
import Toggle from "../form/toggle/Toggle";

export default function DestinationDialog({
  submit,
  id = null,
  name = "",
  countryName = "",
  destinationVisited = false,
  destinationDescription = "",
  destinationImage = "",
}) {
  const { t } = useTranslation();

  const [destinationName, setDestinationName] = useState(name);
  const [country, setCountry] = useState(countryName);
  const [visited, setVisited] = useState(destinationVisited);
  const [description, setDescription] = useState(destinationDescription);
  const [image, setImage] = useState(destinationImage);
  const [imageUpdated, setImageUpdated] = useState(false);

  async function submitForm() {
    if (destinationName && country && description) {
      const submitBody = {
        name: destinationName,
        image: image,
        description: description,
        country: country,
        visited: visited,
      };

      if (id) {
        await submit(id, imageUpdated, submitBody).then(() => {
          toggleUpdateDestinationDialog();
        });
      } else {
        await submit(submitBody).then(() => {
          toggleAddDestinationDialog();
        });
      }
    }
  }

  useEffect(() => {
    if (id) {
      setDestinationName(name);
      setCountry(countryName);
      setVisited(destinationVisited);
      setDescription(destinationDescription);
      setImage(destinationImage);
    }
  }, [
    countryName,
    destinationDescription,
    destinationImage,
    destinationVisited,
    id,
    name,
  ]);

  return (
    <dialog className={id ? "update-destination" : "add-destination"}>
      <div className="add-destination__header">
        <h2 className="add-destination__header--title">
          {t("destinationDialog.title")}
        </h2>
        <button
          className="add-destination__header--close"
          onClick={() =>
            id ? toggleUpdateDestinationDialog() : toggleAddDestinationDialog()
          }
        >
          <CloseIcon />
        </button>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submitForm();
        }}
      >
        <label>{t("destinationDialog.name")}</label>
        <input
          type="text"
          name={t("destinationDialog.name")}
          value={destinationName}
          onChange={(e) => setDestinationName(e.target.value)}
        />
        <label>{t("destinationDialog.country")}</label>
        <SearchSelect
          options={countries}
          onSelect={(selectedValue) => setCountry(selectedValue)}
          selected={countryName}
        />
        <label>{t("destinationDialog.visited")}</label>
        <Toggle
          onToggle={(value) => setVisited(value)}
          value={destinationVisited}
        />
        <label>{t("destinationDialog.description")}</label>
        <textarea
          rows="10"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <label>{t("destinationDialog.image")}</label>
        <ImageUpload
          onUpload={(image) => {
            setImage(image);
            setImageUpdated(true);
          }}
          currentImage={destinationImage}
        />
        <input
          className="button primary"
          type="submit"
          value={t("destinationDialog.submit")}
        />
      </form>
    </dialog>
  );
}
