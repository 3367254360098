import { useNavigate } from "react-router-dom";

import ChevronLeftIcon from "../../icons/ChevronLeftIcon";

export default function DestinationHeader({ title, subtitle, imageUrl }) {
  const navigate = useNavigate();

  return (
    <div
      className="destination-header"
      style={{ backgroundImage: `url(${imageUrl})` }}
    >
      <div className="destination-header__container">
        <div className="container">
          <div className="col-12">
            <span
              className="destination-header__container--back"
              onClick={() => navigate('/')}
            >
              <ChevronLeftIcon fillColor="#ffffff"/>
              Back
            </span>
          </div>
        </div>
        <h1 className="destination-header__container--title">{title}</h1>
        <h3 className="destination-header__container--subtitle">{subtitle}</h3>
      </div>
    </div>
  );
}
