export default function Button({ text, primary = true, ...props }) {
  const { className, ...properties } = props;

  return (
    <button
      className={`button ${primary ? "primary" : "secondary"} ${className}`}
      {...properties}
    >
      {text}
    </button>
  );
}
