import { useEffect, useState } from "react";

import { getDataFromApi, postDataToApi, updateDataToApi } from "../dao/api";
import { postImageToCloudinary } from "../dao/cloudinary";

export default function useDestinations(userId = null, destinationId = null) {
  const [destination, setDestination] = useState({});
  const [destinations, setDestinations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (destinationId) {
      fetchSingleDestination(destinationId);
    } else if (userId) {
      fetchUserDestinations(userId);
    } else {
      fetchAllDestinations();
    }
  }, [userId, destinationId]);

  /**
   * Fetch a single destination.
   *
   * @param {integer} destinationId the ID of the destination to fetch.
   */
  async function fetchSingleDestination(destinationId) {
    setLoading(true);
    try {
      const res = await getDataFromApi(
        `${process.env.REACT_APP_API_URL}/destination/${destinationId}`
      ).then((res) => {
        if (res.ok) {
          return res.json();
        }

        throw new Error(res.message);
      });

      setDestination(res);
    } catch (err) {
      console.error("Something went wrong: ", err);
      setError(err);

      return new Error(err);
    }

    setLoading(false);
  }

  /**
   * Fetch all destinations.
   */
  async function fetchUserDestinations(userId) {
    setLoading(true);
    try {
      const res = await getDataFromApi(
        `${process.env.REACT_APP_API_URL}/destination?userId=${userId}`,
      ).then(async (res) => {
        if (res.ok) {
          return res.json();
        }

        throw new Error(res.message);
      });

      setDestinations(res);
    } catch (err) {
      console.error("Something went wrong: ", err);
      setError(err);

      return new Error(err);
    }

    setLoading(false);
  }

  /**
   * Fetch all destinations.
   */
  async function fetchAllDestinations() {
    setLoading(true);
    try {
      const res = await getDataFromApi(
        `${process.env.REACT_APP_API_URL}/destination`
      ).then(async (res) => {
        if (res.ok) {
          return res.json();
        }

        throw new Error(res.message);
      });

      setDestinations(res);
    } catch (err) {
      console.error("Something went wrong: ", err);
      setError(err);

      return new Error(err);
    }

    setLoading(false);
  }

  /**
   * Post a destination.
   *
   * @param {object} destinationBody destination data to save.
   */
  async function postDestination(destinationBody) {
    setLoading(true);

    if (destinationBody.image) {
      const data = new FormData();
      data.append("file", destinationBody.image);
      data.append(
        "upload_preset",
        process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
      );
      data.append("cloud_name", process.env.REACT_APP_CLOUDINARY_CLOUD_NAME);
      data.append("folder", "Destinations");

      try {
        await postImageToCloudinary(
          `${process.env.REACT_APP_CLOUDINARY_URL}/image/upload`,
          data,
        ).then(async (res) => {
          if (res.ok) {
            const imageData = await res.json();
            destinationBody.image = imageData.url;
            return;
          }

          throw new Error(res.message);
        });
      } catch (err) {
        console.error("Something went wrong: ", err);
        setError(err);

        return new Error(err);
      }
    }

    try {
      await postDataToApi(
        `${process.env.REACT_APP_API_URL}/destination`,
        destinationBody,
      ).then((res) => {
        if (res.ok) {
          return res.json();
        }

        throw new Error(res.message);
      });
    } catch (err) {
      console.error("Something went wrong: ", err);
      setError(err);

      return new Error(err);
    }

    setLoading(false);
  }

  /**
   * Update a destination.
   *
   * @param {number} id destination ID.
   * @param {boolean} imageUploaded whether an image was uploaded.
   * @param {object} destinationBody destination data to update.
   */
  async function updateDestination(id, imageUpdated, destinationBody) {
    setLoading(true);

    if (imageUpdated && destinationBody.image) {
      const data = new FormData();
      data.append("file", destinationBody.image);
      data.append(
        "upload_preset",
        process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
      );
      data.append("cloud_name", process.env.REACT_APP_CLOUDINARY_CLOUD_NAME);
      data.append("folder", "Destinations");

      try {
        await postImageToCloudinary(
          `${process.env.REACT_APP_CLOUDINARY_URL}/image/upload`,
          data,
        ).then(async (res) => {
          if (res.ok) {
            const imageData = await res.json();
            destinationBody.image = imageData.url;
            return;
          }

          throw new Error(res.message);
        });
      } catch (err) {
        console.error("Something went wrong: ", err);
        setError(err);

        return new Error(err);
      }
    }

    try {
      const res = await updateDataToApi(
        `${process.env.REACT_APP_API_URL}/destination/${id}`,
        destinationBody,
      ).then((res) => {
        if (res.ok) {
          return res.json();
        }

        throw new Error(res.message);
      });

      setDestination(res);
    } catch (err) {
      console.error("Something went wrong: ", err);
      setError(err);

      return new Error(err);
    }

    setLoading(false);
  }

  return { destination, destinations, loading, postDestination, updateDestination, error };
}
