import { useTranslation } from "react-i18next";

import Destination from "../../components/destination/Destination";
import useUser from "../../hooks/useUser";
import useDestinations from "../../hooks/useDestinations";
import Loader from "../../components/loader/Loader";

export default function Homepage() {
  const { t } = useTranslation();
  const { user } = useUser();
  const { destinations, loading } = useDestinations(user.id);

  return (
    <div className="homepage">
      <div className="container">
        <div className="col-12">
          <h1>{t("destinations.title")}</h1>
        </div>
        {loading && (
          <div className="col-12">
            <Loader />
          </div>
        )}
        {!loading &&
          destinations?.map((destination, i) => {
            return (
              <div className="col-6 col-lg-4" key={i}>
                <Destination
                  title={destination.name}
                  country={destination.country}
                  imageUrl={destination.image}
                  link={`/destination/${destination.id}`}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
}
