import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export default function SearchSelect({ options, onSelect, selected }) {
  const { t } = useTranslation();

  const [value, setValue] = useState(selected);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectOptions, setSelectOptions] = useState(options);
  const [isFocused, setIsFocused] = useState(false);
  const [error, setError] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    const filteredOptions = options.filter((selectOption) => {
      return selectOption.toLowerCase().includes(searchTerm.toLowerCase());
    });

    setSelectOptions(filteredOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    setValue(selected);
  }, [selected]);

  function handleSelectOption(selectedOption) {
    setValue(selectedOption);
    onSelect(selectedOption);
    setSearchTerm(selectedOption);
    setIsFocused(false);
    setError(false);
  }

  function validateValue() {
    setTimeout(() => {
      setIsFocused(false);

      if (options.includes(inputRef.current.value)) {
        setError(false);
        onSelect(inputRef.current.value);
      } else {
        setError(true);
      }
    }, 100);
  }

  return (
    <div className="search-select">
      <input
        type="text"
        value={value}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          setValue(e.target.value);
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => validateValue()}
        ref={inputRef}
      />
      {isFocused && (
        <ul>
          {selectOptions.map((option) => (
            <li key={option} onClick={() => handleSelectOption(option)}>
              {option}
            </li>
          ))}
        </ul>
      )}
      {error && <p>{t("searchSelect.invalid")}</p>}
    </div>
  );
}
