import { Outlet } from "react-router-dom";

import Navigation from "../navigation/Navigation";
import DestinationDialog from "../destinationDialog/DestinationDialog";
import useDestinations from "../../hooks/useDestinations";

export default function Layout() {
  const { postDestination } = useDestinations();

  return (
    <>
      <Navigation />
      <Outlet />
      <DestinationDialog submit={postDestination} />
    </>
  );
}
