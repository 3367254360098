function toggleDialog(dialog) {
  if (!dialog.open) {
    dialog.showModal();
  } else {
    dialog.close();
  }
}

export function toggleAddDestinationDialog() {
  const dialog = document.querySelector(".add-destination");
  toggleDialog(dialog);
}

export function toggleUpdateDestinationDialog() {
  const dialog = document.querySelector(".update-destination");
  toggleDialog(dialog);
}
