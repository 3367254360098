import { useEffect, useState } from "react";

export default function Toggle({ onToggle, value }) {
  const [checked, setChecked] = useState(value);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  function handleToggle() {
    onToggle(!checked);
    setChecked(!checked);
  }

  return (
    <div className="toggle" onClick={handleToggle}>
      <input className="toggle__input" type="checkbox" checked={checked} readOnly/>
      <span className="toggle__slider" />
    </div>
  );
}
