import { useEffect, useState } from "react";

export default function ImageUpload({onUpload, currentImage}) {
  const [image, setImage] = useState(currentImage);

  useEffect(() => {
    setImage(currentImage);
  }, [currentImage]);

  function handleUpload(uploadedFile) {
    setImage('');

    if (uploadedFile.target.files[0]) {
      setImage(URL.createObjectURL(uploadedFile.target.files[0]));
      onUpload(uploadedFile.target.files[0]);
    }
  }

  return (
    <div className="image-upload">
      <input
        type="file"
        accept="image/*"
        onChange={(e) => handleUpload(e)}
      />
      {image &&
        <img
          src={image}
          alt="Upload"
        />
      }
    </div>
  );
}
