/**
 * Get data from API
 */
export async function getDataFromApi(endpoint) {
  return await fetch(endpoint, {
    method: "GET",
    credentials: "include",
  });
}

/**
 * Post data to API
 */
export async function postDataToApi(endpoint, body) {
  return await fetch(endpoint, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  });
}

/**
 * Post data to API
 */
export async function updateDataToApi(endpoint, body) {
  return await fetch(endpoint, {
    method: "PATCH",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  });
}

