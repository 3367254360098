import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import useUser from "../../hooks/useUser";
import Button from "../../components/button/Button";

export default function ProfilePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { signOut } = useUser();


  async function logOut() {
    await signOut().then(() => {
      navigate("/login");
    });
  }

  return (
    <div className="profile-page">
      <div className="container">
        <div className="col-12">
          <h1>{t("profile.title")}</h1>
          <Button
            text={t("profile.logout")}
            onClick={() => logOut()}
          />
        </div>
      </div>
    </div>
  );
}
