import { Route, Routes } from "react-router-dom";

import Layout from "./components/layout/Layout";
import ProtectedRoute from "./components/protectedRoute/ProtectedRoute";
import Homepage from "./pages/homepage/Homepage";
import LoginPage from "./pages/loginPage/LoginPage";
import DestinationDetailPage from "./pages/destinationDetailPage/DestinationDetailPage";
import ProfilePage from "./pages/profilePage/ProfilePage";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route element={<Layout />}>
          <Route element={<ProtectedRoute />}>
            <Route index element={<Homepage />} />
            <Route path="profile" element={<ProfilePage />} />
            <Route path="destination/:id" element={<DestinationDetailPage />} />
          </Route>
        </Route>
        <Route path="login" element={<LoginPage />} />
      </Routes>
    </div>
  );
}

export default App;
