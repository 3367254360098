import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

import { postDataToApi } from "../dao/api";
import { UserContext } from "../context/UserContext";

export default function useUser() {
  const navigate = useNavigate();
  const cookies = new Cookies();

  const { user, setUser } = useContext(UserContext);
  const [error, setError] = useState(null);

  async function signIn(body) {
    try {
      let error = false;
      const res = await postDataToApi(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        body
      ).then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          error = true;
          return res.json();
        }
      });

      if (error) {
        setError(res.error);
        throw new Error(res.error);
      }

      cookies.set("user", res, {
        path: "/",
        maxAge: 24 * 60 * 60,
      });
      setUser(res);
      navigate("/");
    } catch (err) {
      console.error("Something went wrong: ", err);

      return new Error(err);
    }
  }

  async function signOut() {
    try {
      await postDataToApi(
        `${process.env.REACT_APP_API_URL}/auth/logout`,
        {}
      ).then((res) => {
        if (res.ok) {
          return res.json();
        }

        throw new Error(res.message);
      });

      cookies.remove("user", {
        path: "/",
      });
      setUser(null);
    } catch (err) {
      console.error("Something went wrong: ", err);
      setError(err);

      return new Error(err);
    }
  }

  async function signUp(body) {
    try {
      let error = false;
      const res = await postDataToApi(
        `${process.env.REACT_APP_API_URL}/auth/signup`,
        body
      ).then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          error = true;
          return res.json();
        }
      });

      if (error) {
        setError(res.error);
        throw new Error(res.error);
      }

      cookies.set("user", res, { path: "/" });
      setUser(res);
      navigate("/");
    } catch (err) {
      console.error("Something went wrong: ", err);

      return new Error(err);
    }
  }

  return { user, setUser, signIn, signOut, signUp, error };
}
