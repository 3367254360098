import { createContext, useState } from "react";
import Cookies from "universal-cookie";

export const UserContext = createContext(null);

/**
 * User context provider
 *
 * @returns {JSX.Element}
 */
export default function UserProvider({ children }) {
  const cookies = new Cookies();

  const [ user, setUser ] = useState(cookies.get('user') || null);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
}
