import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Button from "../../components/button/Button";
import useUser from "../../hooks/useUser";

export default function LoginPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [passwordRepeatError, setPasswordRepeatError] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(true);

  const { user, signIn, signUp, error } = useUser();

  if (user) {
    navigate("/");
  }

  async function submitLogin() {
    await signIn({ username, password });
  }

  async function submitSignUp() {
    if (password !== passwordRepeat) {
      setPasswordRepeatError(true);
    } else {
      await signUp({ username, password, email });
    }
  }

  return (
    <div className="login-page">
      <h1>Logo here</h1>
      <div className="login-page__card">
        {showLoginForm ? (
          <form
            className="login-form"
            onSubmit={(e) => {
              e.preventDefault();
              submitLogin();
            }}
          >
            <div className="login-page__form-field">
              <label>{t("login.username")}</label>
              <input
                type="text"
                name={t("login.username")}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="login-page__form-field">
              <label>{t("login.password")}</label>
              <input
                type="password"
                name={t("login.password")}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <input
              className="button primary"
              type="submit"
              value={t("login.login")}
            />
            <Button
              onClick={() => setShowLoginForm(false)}
              text={t("login.createAccount")}
              className="login-form__signup"
            />
          </form>
        ) : (
          <form
            className="login-form"
            onSubmit={(e) => {
              e.preventDefault();
              submitSignUp();
            }}
          >
            <div className="login-page__form-field">
              <label>{t("login.email")}</label>
              <input
                required
                type="email"
                name={t("login.email")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="login-page__form-field">
              <label>{t("login.username")}</label>
              <input
                required
                type="text"
                name={t("login.username")}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="login-page__form-field">
              <label>{t("login.password")}</label>
              <input
                required
                type="password"
                name={t("login.password")}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="login-page__form-field">
              <label>{t("login.passwordRepeat")}</label>
              <input
                required
                type="password"
                name={t("login.passwordRepeat")}
                value={passwordRepeat}
                onChange={(e) => setPasswordRepeat(e.target.value)}
              />
            </div>

            {passwordRepeatError && (
              <p className="login-page__card--error">
                { t("login.passwordRepeatError") }
              </p>
            )}

            <input
              className="button primary"
              type="submit"
              value={t("login.signup")}
            />
            <Button
              onClick={() => setShowLoginForm(true)}
              text={t("login.alreadyHaveAnAccount")}
              className="login-form__signup"
            />
          </form>
        )}
        {error && (
          <p className="login-page__card--error">{error}</p>
        )}
      </div>
    </div>
  );
}
