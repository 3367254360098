import { Link } from "react-router-dom";

export default function Destination({ title, country, imageUrl, link }) {
  return (
    <div
      className="destination"
      style={{ backgroundImage: `url(${imageUrl})` }}
    >
      <Link to={link} className="destination__container">
        <p className="destination__container--label">{country}</p>
        <p>{title}</p>
      </Link>
    </div>
  );
}
