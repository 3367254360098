import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useDestinations from "../../hooks/useDestinations";
import Loader from "../../components/loader/Loader";
import DestinationHeader from "../../components/destinationHeader/DestinationHeader";
import Error from "../../components/error/Error";
import PencilIcon from "../../icons/PencilIcon";
import DestinationDialog from "../../components/destinationDialog/DestinationDialog";
import { toggleUpdateDestinationDialog } from "../../helpers/ToggleDialog";

export default function DestinationDetailPage() {
  const { id } = useParams();
  const { t } = useTranslation();
  const { destination, loading, error, updateDestination } = useDestinations(
    null,
    id
  );

  if (error) {
    return <Error errorMessage={error} />;
  }

  return (
    <>
      <div className="destination-detail-page">
        {loading && (
          <div className="col-12">
            <Loader />
          </div>
        )}
        {!loading && destination && (
          <>
            <DestinationHeader
              title={destination.name}
              subtitle={destination.country}
              imageUrl={destination.image}
            />
            <div className="container">
              <div className="col-12">
                <div className="destination-detail-page__toolbar">
                  <span onClick={() => toggleUpdateDestinationDialog()}>
                    <PencilIcon />
                  </span>
                </div>
                <div className="destination-detail-page__description">
                  <h2>{t("destination.description")}</h2>
                  {destination.description && <p>{destination.description}</p>}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <DestinationDialog
        submit={updateDestination}
        id={destination.id}
        name={destination.name}
        countryName={destination.country}
        destinationVisited={destination.visited}
        destinationDescription={destination.description}
        destinationImage={destination.image}
      />
    </>
  );
}
