import { Link } from "react-router-dom";

import AddIcon from "../../icons/AddIcon";
import HomeIcon from "../../icons/HomeIcon";
import PersonIcon from "../../icons/PersonIcon";
import {toggleAddDestinationDialog} from "../../helpers/ToggleDialog"

export default function Navigation() {
  const navigationItems = [
    {
      title: "Home",
      icon: <HomeIcon/>,
      link: "/",
      button: false,
    },
    {
      title: "Add",
      icon: <AddIcon/>,
      button: true,
    },
    {
      title: "Profile",
      icon: <PersonIcon/>,
      link: "/profile",
      button: false,
    },
  ];

  return (
    <div className="navigation">
      {navigationItems.map((navigationItem) => {
        if (navigationItem.button) {
          return (
            <span
              key={navigationItem.title}
              className={`navigation__item ${navigationItem.button ? "navigation__button" : ""}`}
              onClick={() => toggleAddDestinationDialog()}
            >
              <span className="navigation__item--icon">{navigationItem.icon}</span>
              <span className="navigation__item--text">{navigationItem.title}</span>
            </span>
          );
        } else {
          return (
            <Link
              key={navigationItem.title}
              to={navigationItem.link}
              className={`navigation__item ${navigationItem.button ? "navigation__button" : ""}`}
            >
              <span className="navigation__item--icon">{navigationItem.icon}</span>
              <span className="navigation__item--text">{navigationItem.title}</span>
            </Link>
          );
        }
      })}
    </div>
  );
}
