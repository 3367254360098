export default function HomeIcon({ fillColor = "#000000" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="30"
      viewBox="0 -960 960 960"
      width="30"
      fill={fillColor}
    >
      <path d="M160-120v-480l320-240 320 240v480H560v-280H400v280H160Z" />
    </svg>
  );
}
